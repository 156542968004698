<template>
    <div class="provider-line-page pt-10 text-center">
        <v-row class="qrcodeArea">
            <v-col cols="12" class="text-center pt-10">
                <div class="mb-6 title">供應商QR Code:</div>
                <qr-code-vue :value="lineLink" :size="320" :margin="40" />
            </v-col>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="download">
                    <v-icon size="14" left>fa fa-download</v-icon>
                    {{ "action.download" | t }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="linkArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">QR Code 連結:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="lineLink" class="d-inline-block" outlined dense disabled
                        hide-details></v-text-field>
                    <v-btn color="primary" @click="copy(lineLink)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="idArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">供應商ID:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="layoutProviderLineAtId" outlined dense disabled hide-details
                        class="align-center"></v-text-field>
                    <v-btn color="primary" @click="copy(layoutProviderLineAtId)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="storeLinkArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">商品列表:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="productListLink" outlined dense disabled hide-details
                        class="align-center"></v-text-field>
                    <v-btn color="primary" @click="copy(productListLink)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="storeLinkArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">訂單列表:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="orderListLink" outlined dense disabled hide-details
                        class="align-center"></v-text-field>
                    <v-btn color="primary" @click="copy(orderListLink)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="storeLinkArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">常用購物清單:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="favoriteCartLink" outlined dense disabled hide-details
                        class="align-center"></v-text-field>
                    <v-btn color="primary" @click="copy(favoriteCartLink)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="storeLinkArea inputArea mt-10">
            <v-col class="col-1 col-md-3"></v-col>
            <v-col class="text-center col-10 col-md-6">
                <div class="title mb-4">應付款:</div>
                <div class="d-flex align-center">
                    <v-text-field :value="applyPayLink" outlined dense disabled hide-details
                        class="align-center"></v-text-field>
                    <v-btn color="primary" @click="copy(applyPayLink)">
                        <v-icon size="14">fa
                            fa-copy</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import qrCodeVue from 'qrcode.vue'

import { mapGetters } from 'vuex'

export default {
    components: {
        qrCodeVue
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters('base', [
            'layoutProviderLineAtId',
            'frontendDomain',
        ]),
        QRCodeSRC() {
            return `https://qr-official.line.me/gs/M_${this.layoutProviderLineAtId}_GW.png`
        },
        lineLink() {
            return `https://line.me/ti/p/~${this.layoutProviderLineAtId}`
        },
        productListLink() {
            return `${this.frontendDomain}/provider-store/${this.providerId}/product/list`
        },
        orderListLink() {
            return `${this.frontendDomain}/provider-store/${this.providerId}/orders`
        },
        favoriteCartLink() {
            return `${this.frontendDomain}/provider-store/${this.providerId}/favorite`
        },
        applyPayLink() {
            return `${this.frontendDomain}/provider-store/${this.providerId}/apply-pay/overview`
        },
        providerName() {
            return this.$store.getters['member/providerName'];
        },
        providerId() {
            return this.$store.getters['member/providerId'];
        },
        storeLiffDomain() {
            return this.$store.getters[`base/storeLiffDomain`]
        },
    },
    methods: {
        download() {
            let canvas = document.getElementById('app').getElementsByTagName('canvas')

            let a = document.createElement('a')

            a.href = canvas[0].toDataURL('img/png')
            a.download = `${this.providerName}_qrcode`
            a.click()

        },
        copy(copyText) {
            navigator.clipboard.writeText(copyText)
                .then(() => {
                    this.$snotify.success(null, `已成功複製!`)
                })
        }
    }
}
</script>

<style lang="sass">
.provider-line-page
    .inputArea
        input
            text-align: center !important
            color: rgba(0, 0, 0, 0.87) !important
        fieldset
            border-top-right-radius: initial !important
            border-bottom-right-radius: initial !important
        
        button
            border-top-left-radius: initial !important
            border-bottom-left-radius: initial !important
            height: 40px !important
</style>